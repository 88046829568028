import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import MediaCover from "./MediaCover";
import ChevronRight from "../assets/icons/ChevronRight";
import ChevronLeft from "../assets/icons/ChevronLeft";

const Carousel = ({ title, medias, onClickItem }) => {
  // slider
  const sliderRef = useRef(null);
  const sliderPrevious = () => sliderRef.current.slickPrev();
  const sliderNext = () => sliderRef.current.slickNext();
  const sliderGoto = (slide) => sliderRef.current.slickGoTo(slide);

  const [isSwiping, setIsSwiping] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [slickGoTo, setSlickGoTo] = useState({ slideIndex: 0 });

  const settings = {
    infinite: false,
    beforeChange: (currentSlide, nextSlide) => {
      if (!isSwiping) {
        setIsSwiping(true);
      }
      setSlickGoTo((prev) => ({
        ...prev,
        slideIndex: nextSlide,
      }));
    },
    arrows: medias.length > 5,
    slidesToShow: medias.length < 5 ? medias.length : 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: medias.length > 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (isSwiping) {
      setIsSwiping(false);
    }
  }, [slickGoTo]);

  const arrows = medias.length > 5;

  useEffect(() => {
    if (isMounted && settings.slidesToScroll === 0) {
      const el = document.querySelector(".slick-track");
      if (el) {
        el.classList.add("flex", "justify-center", "w-auto");
      }
    }
  }, [isMounted]);

  return (
    <div className="relative grow">
      <div className="flex flex-row justify-between px-page pb-1 max-w-[97.3%] mx-auto">
        <span
          className="text-xl uppercase font-lacosteExpandedBold"
          style={{ color: "var(--primary)" }}
        >
          {title}
        </span>
        {arrows && medias.length > 5 && (
          <input
            onChange={(e) => sliderGoto(+e.target.value)}
            value={slickGoTo.slideIndex}
            type="range"
            min={0}
            max={medias.length > 5 ? medias.length - 5 : 0}
            style={{ accentColor: "var(--primary)" }}
            className="hidden lg:flex bg-primary"
          />
        )}
      </div>

      {arrows && (
        <>
          <div className="absolute text-center left-6 top-1/2 z-10 rounded-full bg-white border border-black hover:-translate-x-2 transition duration-100">
            <button
              className="flex justify-center items-center w-11 h-11 -translate-x-0.5 text-primary"
              onClick={sliderPrevious}
              type="button"
            >
              <ChevronLeft />
            </button>
          </div>
          <div className="absolute text-center right-6 top-1/2 z-10 rounded-full bg-white border border-black hover:translate-x-2 transition duration-100">
            <button
              className="flex justify-center items-center w-11 h-11 translate-x-0.5 text-primary"
              onClick={sliderNext}
              type="button"
            >
              <ChevronRight />
            </button>
          </div>
        </>
      )}
      {medias.length > 0 ? (
        <Slider
          // eslint-disable-next-line
          ref={(slider) => {
            sliderRef.current = slider;
            setIsMounted(true);
          }}
          style={{
            WebkitAppearance: "none",
          }}
          className="max-w-[95%] mx-auto"
          {...settings}
        >
          {medias.map((media, index) => (
            <MediaCover
              key={index}
              media={media}
              hasData={
                (null !== media.info && "" !== media.info) ||
                media.data?.length > 0 ||
                media.refGroups?.length > 0
              }
              onClick={(media) => !isSwiping && onClickItem(media)}
            />
          ))}
        </Slider>
      ) : (
        <div className="flex items-center h-full px-page">
          <span className="text-xl">No content</span>
        </div>
      )}
    </div>
  );
};

Carousel.propTypes = {
  title: PropTypes.string,
  medias: PropTypes.array,
  onClickItem: PropTypes.func,
};

export default Carousel;
