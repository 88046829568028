import { useTranslation } from "react-i18next";
import Carousel from "../Carousel";
import getMedias from "../../utils/getMedias";

const ContentsView = ({ items, onClickItem }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      {items?.length > 0 ? (
        items.map((contentGroup, i) => (
          <Carousel
            key={i}
            title={contentGroup.title ?? ""}
            medias={getMedias(contentGroup)}
            onClickItem={onClickItem}
          />
        ))
      ) : (
        <span> {t("no_contents")} </span>
      )}
    </div>
  );
};

export default ContentsView;
