import translateKey from "./translateKey";

const getMedias = (contentGroup) => {
  return contentGroup.contents.map((content) => ({
    id: content.id,
    image: content.values.content_image?.[0].data.url, // @todo should be localized
    name:
      translateKey(content.names) !== "-" ? translateKey(content.names) : "",
    info: content.values.content_description?.[0].data || null, // @todo should be localized
    price: content.values.attribute_prix?.[0].data || null, // @todo should be localized
    refGroups: content.values.attribute_group_ref?.[0].data
      ? JSON.parse(content.values.attribute_group_ref?.[0].data)
      : [], // @todo should be localized
    data: content.values.attribute_materials?.[0].data
      ? JSON.parse(content.values.attribute_materials?.[0].data)
      : [], // @todo should be localized
  }));
};

export default getMedias;