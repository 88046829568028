import { useTranslation } from "react-i18next";

const Header = ({ handleClose, handleDownload, handlePrint, count = null }) => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between border-b p-1 modal-header">
      {handleClose && (
        <button
          className="flex gap-2 items-center hover:underline"
          onClick={() => handleClose()}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <title>Close icon</title>
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>

          <span className="text-sm font-lacosteExpandedBold uppercase hidden md:block">
            {t("close")}
          </span>
        </button>
      )}
      <div className="flex items-center">
        {count ? count : null}
        {handleDownload && (
          <button
            className="flex gap-2 items-start hover:underline text-primary mr-4"
            onClick={() => handlePrint()}
            type="button"
          >
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Download icon</title>
              <path
                fill="currentColor"
                d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"
              />
            </svg>

            <span className="text-sm font-lacosteExpandedBold uppercase hidden md:block text-primary">
              {t("informations")}
            </span>
          </button>
        )}
        {handlePrint && (
          <button
            className="flex gap-2 items-start hover:underline text-primary"
            onClick={() => handleDownload()}
            type="button"
          >
            <svg
              width={20}
              height={20}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Download icon</title>
              <path
                fill="currentColor"
                d="M5 20h14v-2H5zM19 9h-4V3H9v6H5l7 7z"
              />
            </svg>
            <span className="text-sm font-lacosteExpandedBold uppercase hidden md:block text-primary">
              {t("images")}
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
