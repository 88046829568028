import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import Loader from "../Loader";
import Header from "./Header";
import MediaData from "./MediaData";
import RefGroup from "./RefGroup";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import { useLocale } from "../../context/LocaleContext";
import getMedias from "../../utils/getMedias";
import useOutsideClick from "../../hooks/useClickOutside";
import ChevronLeft from "../../assets/icons/ChevronLeft";
import ChevronRight from "../../assets/icons/ChevronRight";

const MediaModal = ({ items, currentIdx, isOpen, onClose }) => {
  const locale = useLocale();
  const { t } = useTranslation();
  const imageRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const [loaded, setLoaded] = useState(false);

  const itemsFormatted = items.map((item) => {
    return getMedias(item);
  });

  const mediaIndex = itemsFormatted
    .find((itemArray) => itemArray.some((item) => currentIdx.id === item.id))
    .findIndex((item) => currentIdx.id === item.id);

  const carouselIndex = itemsFormatted.findIndex((itemArray) =>
    itemArray.some((item) => currentIdx.id === item.id)
  );

  const [position, setPosition] = useState(mediaIndex);

  const media = itemsFormatted[carouselIndex][position];
  const length = itemsFormatted[carouselIndex].length;

  const handleLoad = () => {
    if (imageRef) {
      setLoaded(true);
    }
  };

  const handlePositionChange = (direction) => {
    setPosition((prevPosition) => {
      const newPosition = prevPosition + direction;

      if (newPosition < 0) {
        return length - 1;
      }
      if (newPosition >= length) {
        return 0;
      }
      return newPosition;
    });
  };

  const ref = useOutsideClick(onClose);

  useEffect(() => {
    if (document.querySelector("#modal-image-container")?.clientHeight) {
      setDimension({
        width: imageRef.current.width,
        height: document.querySelector("#modal-image-container").clientHeight,
      });
    }
  }, [loaded]);

  return (
    <Modal
      id={"media-print"}
      innerRef={ref}
      isOpen={isOpen}
      header={
        <Header
          handleClose={onClose}
          handleDownload={() =>
            (window.location.href = `${media.image}?download=1`)
          }
          handlePrint={() => {
            const newWindow = window.open(`/${locale}/pdf`);
            newWindow.data = media;
          }}
          count={
            length > 1 ? (
              <span className="text-sm mr-2">
                {position + 1} / {length}
              </span>
            ) : null
          }
        />
      }
    >
      <div className="flex flex-col bg-white">
        <div
          className={` ${
            media.data ? "box-border " : ""
          } grow flex flex-col lg:flex-row lg:overflow-hidden lg:max-h-[85dvh]`}
        >
          {length > 1 ? (
            <div className="min-h-full flex flex-col justify-center items-center px-3">
              <button
                className="flex justify-center items-center w-11 h-11 text-primary rounded-full bg-white border border-black"
                onClick={() => handlePositionChange(-1)}
                type="button"
              >
                <ChevronLeft />
              </button>
            </div>
          ) : null}
          <div id="modal-image-container" className="p-2">
            {!loaded && (
              <div className="border-4 animate-pulse w-[500px] h-[600px] m-2 flex justify-center items-center">
                <Loader />
              </div>
            )}

            <div
              className={`flex element-a ${
                media.name && media.price ? "justify-between" : "justify-center"
              }`}
            >
              {media.name && (
                <span className="text-sm lg:text-base font-lacosteExpandedBold leading-tight">
                  {media.name}
                </span>
              )}
              {media.price && (
                <span className="text-sm lg:text-base font-lacosteExpandedBold">
                  {media.price}
                </span>
              )}
            </div>
            <div className="modal-print-content">
              <img
                ref={(el) => (imageRef.current = el)}
                onLoad={() => {
                  handleLoad();
                }}
                src={media.image}
                className={`object-scale-down w-full h-full max-h-[100dvh] lg:max-h-[80dvh] max-w-[90vw] ${
                  !loaded ? "invisible" : "visible"
                }`}
                alt="media"
              />
            </div>
          </div>
          {(media.info ||
            media.refGroups?.length > 0 ||
            media.data?.length > 0) && (
            <div className="text-group lg:overflow-y-auto p-2 border-l uppercase lg:max-w-[25vw]">
              {media.info && (
                <div className="informations-modal-1 text-left">
                  <h1 className="uppercase font-lacosteExpandedBold text-sm">
                    {t("information")}
                  </h1>
                  <p dangerouslySetInnerHTML={{ __html: media.info }}></p>
                </div>
              )}
              {media.info && media.refGroups?.length > 0 && (
                <hr className="my-2" color="#000000" />
              )}
              {media.refGroups?.[0]?.title !== "" ||
              media.refGroups?.length > 0 ? (
                <div className="informations-modal-2 text-left">
                  <h1 className="uppercase font-lacosteExpandedBold text-sm">
                    {t("references")}
                  </h1>
                  {media.refGroups.map((group, i) => (
                    <RefGroup key={i} group={group} />
                  ))}
                </div>
              ) : null}
              {media.data && media.data.length > 0 && (
                <div className="p-2 md:border-b">
                  {media.data.map((d, i) => (
                    <MediaData key={i} data={d} />
                  ))}
                </div>
              )}
            </div>
          )}
          {length > 1 ? (
            <div className="min-h-full flex flex-col justify-center items-center px-3">
              <button
                className="flex justify-center items-center w-11 h-11 text-primary rounded-full bg-white border border-black"
                onClick={() => handlePositionChange(+1)}
                type="button"
              >
                <ChevronRight />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

MediaModal.propTypes = {
  media: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.string,
    info: PropTypes.string,
    price: PropTypes.string,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    refGroups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        refs: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
          })
        ),
      })
    ),
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MediaModal;
