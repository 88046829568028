const Modal = ({ children, header, isOpen, id, innerRef }) => {
  return isOpen && (
    <div
      id={id}
      className={`relative z-20 ${
        isOpen ? "visible opacity-100 duration-300" : "invisible opacity-0"
      } transition-all ease-in-out`}
    >
      <div className="fixed inset-0 z-10 w-screen bg-black bg-opacity-75">
        <div className="flex h-full justify-center overflow-y-auto p-4 print-layout">
          {isOpen && (
            <div
              ref={innerRef}
              className="flex flex-col justify-center overflow-y-auto shadow-xl transition-all my-auto print-width"
            >
              <div className="sticky w-full bg-white">{header}</div>
              {children && (
                <div className="lg:overflow-y-hidden overflow-auto">
                  {children}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
