import { useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "../assets/icons/DownloadIcon";
import Modal from "./Modal";
import ModalHeader from "./Modals/ModalHeader";
import http from "../utils/http.utils";

export default function ButtonFilesCategories({ category, label = false }) {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  const downloadAll = async () => {
    try {
      const zip = await http.post("/api/zips", { urls: selected.map((file) => `/api/media-objects/${file}/binary`) });
      launchCheckZip(zip);
    } catch (error) {
      console.error(error);
    }
  };

  const launchCheckZip = (zip) => {
    setTimeout(() => checkZip(zip), 2000);
  };

  const checkZip = async (zip) => {
    try {
      const updated = await http.get(`${zip["@id"]}`);
      if (updated.isReady) {
        const url = `${process.env.REACT_APP_BACK_URL}${updated["@id"]}/binary?token=${http.getToken()}`;
        window.open(url);
      } else {
        launchCheckZip(zip);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const files = category
    ? Object.keys(category?.values)
        ?.map((key) => (key.includes("category_file_") ? category.values[key][0] : null))
        .filter((file) => file)
    : [];

  const selectAllRows = (e) => {
    if (e.target.checked) {
      setSelected(files.map((file) => file.data.id));
    } else {
      setSelected([]);
    }
  };

  const selectRow = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected((selected) => [...selected, id]);
    }
  };

  if (files.length === 0) {
    return null;
  }

  return (
    <>
      <button
        type="button"
        className="break-words flex items-center bg-primary hover:bg-primary-400 transition-all duration-200 text-white text-xs gap-2 p-3 rounded font-lacosteExpandedBold cursor-pointer"
        rel="noreferrer"
        onClick={() => setModalIsOpen(true)}
      >
        <DownloadIcon fill="white" />
        <span className="uppercase whitespace-break-spaces">{label || t("files")}</span>
      </button>
      <Modal isOpen={modalIsOpen} id="modal-tutorial">
        <div
          className="flex flex-col p-4"
          style={{
            width: "80dvw",
          }}
        >
          <ModalHeader title={t("files")} onClose={() => setModalIsOpen(false)} />
          <div
            className="bg-white"
            style={{
              height: "80dvh",
              overflow: "auto",
            }}
          >
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="p-4">
                    <div className="flex items-center gap-2">
                      <input type="checkbox" name="select_all" className="w-4 h-4" onChange={selectAllRows} />
                      <label for="select_all">{t("select_all")}</label>
                    </div>
                  </th>
                  <th />
                  <th className="p-4">
                    <div className="flex justify-end">
                      {selected.length > 0 && (
                        <button type="button" onClick={downloadAll} className="rounded-full p-2 bg-primary text-white">
                          {t("download_selection")}
                        </button>
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr key={file.id} className={`group hover:bg-primary-500 hover:text-white ${index % 2 !== 0 ? "bg-white" : "bg-primary-100"}`}>
                    <td className="p-4">
                      <input type="checkbox" checked={selected.includes(file.data.id)} className="w-4 h-4" onChange={() => selectRow(file.data.id)} />
                    </td>
                    <td className="whitespace-nowrap">{file.data.name}</td>
                    <td className="p-4 lg:max-w-16">
                      <div className="flex justify-end">
                        <button type="button" className="rounded-full p-2 bg-primary text-white group-hover:bg-white group-hover:text-primary">
                          <a href={`${file.data.url}?download=1`} download>
                            {t("download")}
                          </a>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
}
