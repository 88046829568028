import { useTranslation } from "react-i18next";
import iconPlay from "../assets/icons/picto-play.svg";
import { useUser } from "../context/UserProvider";
import http from "../utils/http.utils";
import Modal from "./Modal";
import { useState } from "react";
import ModalHeader from "./Modals/ModalHeader";
import Loader from "./Loader";

export default function ButtonTutorial({ href, icon = false, label = false }) {
  const { t } = useTranslation();
  const { user, persistUser } = useUser();
  const [iframeIsLoading, setIframeIsLoading] = useState(true);

  const isVisited = user?.view?.includes(href);

  const pushView = async (newView) => {
    const views = [...user.view, newView];
    setModalIsOpen(true);
    try {
      const result = await http.post("/api/user-views", {
        view: views,
      });
      await persistUser(result);
    } catch (e) {
      console.log(e);
    }
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <button
        className={`${
          isVisited ? "" : "animate-pulse-fast"
        }  break-words flex align-center bg-primary hover:bg-primary-400 transition-all duration-200 text-white text-xs gap-2 p-3 rounded font-lacosteExpandedBold cursor-pointer`}
        target="_blank"
        rel="noreferrer"
        onClick={isVisited ? () => setModalIsOpen(true) : (e) => pushView(href)}
      >
        <img src={icon || iconPlay} alt="import" width={15} height={15} />
        <span className="uppercase whitespace-break-spaces">
          {label || t("tutorial_video")}
        </span>
      </button>
      <Modal isOpen={modalIsOpen} id="modal-tutorial">
        <div
          className="flex flex-col p-4"
          style={{
            width: "80dvw",
          }}
        >
          <ModalHeader
            title={t("tutorial_video")}
            onClose={() => setModalIsOpen(false)}
          />
          <div
            className="aspect-w-96 aspect-h-9"
            style={{
              height: "80dvh",
            }}
          >
            {iframeIsLoading && (
              <div className="flex justify-center items-center h-full bg-white">
                <Loader />
              </div>
            )}
            <iframe
              src={href}
              title={t("tutorial_video")}
              className="w-full h-full"
              onLoad={() => setIframeIsLoading(false)}
            ></iframe>
          </div>
        </div>
      </Modal>
    </>
  );
}
