import { useTranslation } from "react-i18next";

export default function ModalHeader({ title, onClose }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-white p-2 flex justify-between">
        <h3>{title}</h3>
        <button
          className="flex gap-2 items-center hover:underline"
          onClick={() => onClose(false)}
          type="button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <title>Close icon</title>
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
              clipRule="evenodd"
            />
          </svg>

          <span className="text-sm font-lacosteExpandedBold uppercase hidden md:block">
            {t("close")}
          </span>
        </button>
      </div>
      <hr />
    </>
  );
}
