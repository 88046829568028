import { useTranslation } from "react-i18next";
import IconCalendar from "../../assets/icons/calendar.svg";
import IconCross from "../../assets/icons/cross.svg";
import IconLogout from "../../assets/icons/deconnexion.svg";
import LogoLacoste from "../../assets/icons/logo_lacoste.svg";
import IconMenu from "../../assets/icons/menu.svg";
import IconMessage from "../../assets/icons/picto-messagerie.svg";
// import profileIcon from "../../assets/icons/picto-profil.svg";
import { Link, useNavigate } from "react-router-dom";
import HeaderButton from "./HeaderButton";
import Notifications from "./Notifications";
import { useLocale } from "../../context/LocaleContext";
import { useUser } from "../../context/UserProvider";

export default function Header({ openDrawer, toggleDrawer }) {
  const { user, setIsOpen } = useUser();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const locale = useLocale();

  const handleClickCalendar = async () => {
    navigate(`/${locale}/calendars`);
  };

  return (
    <div className="shrink-0 flex justify-between items-center py-page md:p-page z-10">
      <div className="flex items-center gap-4 -translate-x-2">
        <button
          type="button"
          onClick={toggleDrawer}
          className="flex items-center"
        >
          <img
            src={IconCross}
            alt="menu"
            className={` ${
              openDrawer ? "translate-x-4 w-8" : "-translate-x-20 w-4"
            } transition-all duration-300 hover:bg-primary-light rounded-full`}
          />
          <img
            src={IconMenu}
            alt="menu"
            className={` ${
              !openDrawer ? "-translate-x-0.5 w-8" : "-translate-x-20 w-4"
            } transition-all duration-300 ease-in-out hover:bg-primary-light rounded-full`}
          />
        </button>
        <Link
          to={`/${locale}/`}
          className="flex items-center gap-3 w-48 md:w-64"
        >
          <img
            src={LogoLacoste}
            alt="logo-lacoste"
            // className="object-cover"
          />
        </Link>
      </div>

      <div className="flex items-end gap-1 z-50">
        {/* {process.env.REACT_APP_TEMP_FORM ? (
          <button
            type="button"
            onClick={() => setIsOpen(true)}
            className="text-white self-center mt-1 bg-primary border border-primary focus:outline-none font-medium rounded-md text-sm px-5 py-1 me-2 light:bg-gray-800 light:text-white light:border-gray-600 light:hover:bg-gray-700 light:hover:border-gray-600"
          >
            CX Platform survey
          </button>
        ) : null} */}
        {/* <div className="hidden md:block h-max self-center mx-4">
          <LangSwitcher />
        </div> */}

        {false && user && (
          <div>
            <button
              type="button"
              // onClick={createSynchronization}
              // title={synchronization?.endedAt}
            >
              <HeaderButton src={IconCalendar} />
            </button>
          </div>
        )}
        <Notifications />
        <div>
          <div onClick={handleClickCalendar} className="cursor-pointer">
            <HeaderButton src={IconCalendar} />
          </div>
        </div>
        <div className="hidden md:block">
          <Link to={`/${locale}/contact`}>
            <HeaderButton src={IconMessage} />
          </Link>
        </div>
        <div className="hidden md:block">
          <Link
            to={`/${locale}/logout`}
            title={`${t("logout")} ${user?.email}`}
          >
            <HeaderButton src={IconLogout} />
          </Link>
        </div>
      </div>
    </div>
  );
}
